import * as Configuration from './configuration'
import * as Constants from './constants'
import * as Forms from '@rushplay/forms'
import * as Payments from './payments'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Reselect from 'reselect'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'

export function NodapayProcessing(props) {
  const [iframeSrc, setIframeSrc] = React.useState(null)

  const amountCents = Forms.useField('#/properties/amount', {noRegister: true})
    .value
  const apiUrl = ReactRedux.useSelector(Configuration.getApiUrl)
  const getPlayerData = Reselect.createStructuredSelector({
    sessionToken: Configuration.getToken,
  })
  const playerData = ReactRedux.useSelector(getPlayerData)
  const {transactionType} = Payments.useTransactionContext()

  React.useEffect(() => {
    function messageReceive(event) {
      if (event.key == 'nodapayMessage') {
        const message = JSON.parse(event.newValue)

        switch (message.result) {
          case 'done':
            props.onStepChange(Constants.TransactionStep.Success)
            break

          case 'failed':
            props.onStepChange(Constants.TransactionStep.Failure)
            break

          default:
            props.onStepChange(Constants.TransactionStep.Failure)
        }

        localStorage.removeItem('nodapayMessage')
      }
    }

    window.addEventListener('storage', messageReceive)

    return () => window.removeEventListener('storage', messageReceive)
  })

  React.useEffect(() => {
    const cashierJson = {
      amount_cents: amountCents,
      transaction_type: transactionType,
    }

    fetch(`${apiUrl}/nodapay/payments`, {
      method: 'POST',
      headers: {
        'Accept': 'application/vnd.casinosaga.v1, application/json',
        'Authorization': playerData.sessionToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cashierJson),
    })
      .then((response) => response.json())
      .then((data) => {
        setIframeSrc(data.url)
      })
  }, [])

  if (!iframeSrc) {
    return (
      <React.Fragment>
        <ThemeUi.Flex sx={{justifyContent: 'center'}}>
          <ThemeUi.Spinner />
        </ThemeUi.Flex>
      </React.Fragment>
    )
  }

  return (
    <ThemeUi.Flex
      sx={{
        'alignItems': 'center',
        'justifyContent': 'center',
        'flexDirection': 'column',
        'height': '100%',
        '#nodapay-payment-system': {
          height: '100%',
          width: '100%',
          maxWidth: '600px',
        },
      }}
    >
      <iframe
        src={iframeSrc}
        sandbox="allow-forms allow-modals allow-orientation-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts"
        style={{border: 'none'}}
      />
    </ThemeUi.Flex>
  )
}

NodapayProcessing.propTypes = {
  onStepChange: PropTypes.func.isRequired,
}
