import * as FormComponents from './form-components'
import * as Forms from '@rushplay/forms'
import * as React from 'react'

import PropTypes from 'prop-types'
import {uiSchema} from './ui-schema'

/**
 * Used to automatically create forms based of JSON-schemas
 * @component FieldBuilder
 * @param {Object} props Components props
 * @param {Object} props.initialValues Initial values for the generated form-controls
 * @param {string} props.name name of the generated form
 * @returns {ReactNode}
 */
export function FieldBuilder(props) {
  return (
    <Forms.FieldBuilder
      components={{
        box: FormComponents.Box,
        booleanField: FormComponents.CheckboxField,
        multipleSelectField: FormComponents.MultipleSelectField,
        muchBetterSignUpLink: FormComponents.MuchBetterSignUpLink,
        directa24PaymentMethods: FormComponents.Directa24PaymentMethods,
        paymeroNetBankingCodeSelect: FormComponents.PaymeroNetBankingCodeSelect,
        paymeroWalletCodeSelect: FormComponents.PaymeroWalletCodeSelect,
        paymeroWithdrawalsCodeSelect:
          FormComponents.PaymeroWithdrawalsCodeSelect,
        numberField: FormComponents.NumberInputField,
        singleSelectField: FormComponents.SelectField,
        submit: FormComponents.SubmitButton,
        text: FormComponents.Text,
        textField: FormComponents.InputField,
        vegaWalletTermsLink: FormComponents.VegaWalletLink,
      }}
      initialValues={props.initialValues}
      name={props.name}
      uiSchema={uiSchema}
      getCustomField={(scope) => {
        switch (scope) {
          case '#/definitions/PiqAstropaybankPixWithdrawal/properties/service':
          case '#/definitions/PiqAstropaybankD24indiaWithdrawal/properties/service':
          case '#/definitions/PiqAstropaycardGoDeposit/properties/service':
          case '#/definitions/PiqAstropaycardNbDeposit/properties/service':
          case '#/definitions/PiqAstropaycardPhDeposit/properties/service':
          case '#/definitions/PiqAstropaycardTmDeposit/properties/service':
          case '#/definitions/PiqAstropaycardUiDeposit/properties/service':
          case '#/definitions/PiqCryptocurrencyBitpaceWithdrawal/properties/service':
          case '#/definitions/PiqCryptocurrencyBitpaceDeposit/properties/service':
          case '#/definitions/PiqCryptocurrencyCryptopayDeposit/properties/service':
          case '#/definitions/PiqCryptocurrencyCryptopayWithdrawal/properties/service':
          case '#/definitions/PiqBankBlDeposit/properties/service':
          case '#/definitions/PiqBankIxDeposit/properties/service':
          case '#/definitions/PiqBankJcDeposit/properties/service':
          case '#/definitions/PiqBankBtvoucherDeposit/properties/service':
          case '#/definitions/PiqBankBtvoucherAtmDeposit/properties/service':
          case '#/definitions/PiqBankPpbtvoucherDeposit/properties/service':
          case '#/definitions/PiqBankNbDeposit/properties/service':
          case '#/definitions/PiqBankUiDeposit/properties/service':
          case '#/definitions/PiqBankibanBriteWithdrawal/properties/service':
          case '#/definitions/PiqBankibanZimplerWithdrawal/properties/service':
          case '#/definitions/PiqBankibanEbpWithdrawal/properties/service':
          case '#/definitions/PiqBankibanNodapayWithdrawal/properties/service':
          case '#/definitions/PiqBankintlInpayWithdrawal/properties/service':
          case '#/definitions/PiqBankintlNzdWithdrawal/properties/service':
          case '#/definitions/PiqBankInovapayDeposit/properties/service':
          case '#/definitions/PiqBankNetBankingDeposit/properties/service':
          case '#/definitions/PiqBankRedeemVoucherDeposit/properties/service':
          case '#/definitions/PiqBankPixDeposit/properties/service':
          case '#/definitions/PiqBankUpiDeposit/properties/service':
          case '#/definitions/PiqBankVoltDeposit/properties/service':
          case '#/definitions/PiqBankVoltWithdrawal/properties/service':
          case '#/definitions/PiqBankWalletDeposit/properties/service':
          case '#/definitions/PiqCreditcardVisaDeposit/properties/service':
          case '#/definitions/PiqCreditcardRupayDeposit/properties/service':
          case '#/definitions/PiqCreditcardMastercardDeposit/properties/service':
          case '#/definitions/PiqCreditcardAmexDeposit/properties/service':
          case '#/definitions/PiqCreditcardJcbDeposit/properties/service':
          case '#/definitions/PiqInovapaybankPixWithdrawal/properties/service':
          case '#/definitions/PiqInterkassawalletWalletDeposit/properties/service':
          case '#/definitions/PiqInterkassawalletUpiDeposit/properties/service':
          case '#/definitions/PiqInterkassawalletUpiWithdrawal/properties/service':
          case '#/definitions/PiqPayretailersWithdrawal/properties/documentType':
          case '#/definitions/PiqPayretailersPixWithdrawal/properties/documentType':
          case '#/definitions/PiqPayretailersPixWithdrawal/properties/service':
          case '#/definitions/PiqPproDeposit/properties/service':
          case '#/definitions/PiqWebredirectApplepayDeposit/properties/service':
          case '#/definitions/PiqWebredirectGooglepayDeposit/properties/service':
          case '#/definitions/PiqWebredirectOpDeposit/properties/service':
          case '#/definitions/PiqWebredirectPoliDeposit/properties/service':
          case '#/definitions/PiqWebredirectOnlineBankingDeposit/properties/service':
          case '#/definitions/PiqWebredirectEbpDeposit/properties/service':
          case '#/definitions/PiqWebredirectMacropayDeposit/properties/service':
          case '#/definitions/PiqWebredirectNodapayDeposit/properties/service':
          case '#/definitions/PiqWebredirectBoletoDeposit/properties/service':
          case '#/definitions/PiqWebredirectDirectPaymentDeposit/properties/service':
          case '#/definitions/PiqWebredirectBoletoPixDeposit/properties/service':
          case '#/definitions/PiqWebredirectBtDeposit/properties/service':
          case '#/definitions/PiqEzeebillBtjpnWithdrawal/properties/service':
          case '#/definitions/PiqWebredirectJpayDeposit/properties/service':
          case '#/definitions/PiqWebredirectZotapayDeposit/properties/service':
          case '#/definitions/PiqWebredirectSumopayDeposit/properties/service':
          case '#/definitions/PiqWebredirectOnrampDeposit/properties/service':
          case '#/definitions/PiqWebredirectPixOnlineDeposit/properties/service':
          case '#/definitions/PiqWebredirectQaicashDeposit/properties/service':
          case '#/definitions/PiqWebredirectQaicashccDeposit/properties/service':
          case '#/definitions/PiqWebredirectPointinoutDeposit/properties/service':
          case '#/definitions/PiqWebredirectQpointsDeposit/properties/service':
          case '#/definitions/PiqWebredirectIbDeposit/properties/service':
          case '#/definitions/PiqWebredirectZotacardDeposit/properties/service':
          case '#/definitions/PiqWebredirectOnrampWithdrawal/properties/service':
          case '#/definitions/PiqWebredirectQaicashWithdrawal/properties/service':
          case '#/definitions/PiqWebredirectPointinoutWithdrawal/properties/service':
          case '#/definitions/PiqWebredirectQpointsWithdrawal/properties/service':
          case '#/definitions/PiqWebredirectBdbanksDeposit/properties/service':
          case '#/definitions/PiqWebredirectUpiDeposit/properties/service':
          case '#/definitions/PiqWebredirectVisaDeposit/properties/service':
          case '#/definitions/PiqWebredirectMastercardDeposit/properties/service':
          case '#/definitions/PiqWebredirectQrpaymentQuicktransferDeposit/properties/service':
          case '#/definitions/PiqWebredirectBanktransferQuicktransferDeposit/properties/service':
          case '#/definitions/PiqWebredirectUpiQuicktransferDeposit/properties/service':
          case '#/definitions/PiqWebredirectP2pQuicktransferDeposit/properties/service':
          case '#/definitions/PiqMifinityUnionpayDeposit/properties/service':
          case '#/definitions/PiqMifinityKlarnaDeposit/properties/service':
          case '#/definitions/PiqWebredirectRupayDeposit/properties/service':
          case '#/definitions/PiqWebredirectPaytmDeposit/properties/service':
          case '#/definitions/PiqWebredirectImpsDeposit/properties/service':
          case '#/definitions/PiqWebredirectGpayDeposit/properties/service':
          case '#/definitions/PiqWebredirectPhonepeDeposit/properties/service':
          case '#/definitions/PiqWebredirectOnlineDebitDeposit/properties/service':
          case '#/definitions/PiqWebredirectUpiH5Deposit/properties/service':
          case '#/definitions/PiqWebredirectPaytmWalletDeposit/properties/service':
          case '#/definitions/PiqWebredirectPhonepeWalletDeposit/properties/service':
          case '#/definitions/PiqWebredirectMobikwikWalletDeposit/properties/service':
          case '#/definitions/PiqWebredirectAirtelWalletDeposit/properties/service':
          case '#/definitions/PiqWebredirectJioWalletDeposit/properties/service':
          case '#/definitions/PiqWebredirectCreditCardDeposit/properties/service':
          case '#/definitions/PiqWebredirectTigerpayDeposit/properties/service':
          case '#/definitions/PiqWebredirectBtbDeposit/properties/service':
          case '#/definitions/PiqWebredirectBtbAutoDeposit/properties/service':
          case '#/definitions/PiqWebredirectTigerpayWithdrawal/properties/service':
          case '#/definitions/PiqWebredirectUpiWithdrawal/properties/service':
          case '#/definitions/PiqWebredirectLocalBankTransferWithdrawal/properties/service':
          case '#/definitions/PiqWebredirectPneDeposit/properties/service':
          case '#/definitions/PiqBanklocalUpiWithdrawal/properties/service':
          case '#/definitions/PiqBanklocalBtbWithdrawal/properties/service':
          case '#/definitions/PiqBanklocalLocalBankTransferWithdrawal/properties/service':
          case '#/definitions/PiqBanklocalSumopayWithdrawal/properties/service':
          case '#/definitions/PiqBanklocalInpayjpWithdrawal/properties/service':
          case '#/definitions/PiqBanklocalPagavaWithdrawal/properties/service':
          case '#/definitions/PiqBanklocalInterkassaWithdrawal/properties/service':
          case '#/definitions/PiqGate2waywalletPaytmWithdrawal/properties/service':
          case '#/definitions/PiqBanklocalImpsWithdrawal/properties/service':
          case '#/definitions/PiqWebredirectSparkasseDeposit/properties/service':
          case '#/definitions/PiqWebredirectSparkasseDeposit/properties/providerId':
          case '#/definitions/PiqWebredirectTrustlyusDeposit/properties/service':
          case '#/definitions/PiqWebredirectVolksbankenDeposit/properties/service':
          case '#/definitions/PiqWebredirectVolksbankenDeposit/properties/providerId':
          case '#/definitions/PiqWebredirectDeutschebankDeposit/properties/service':
          case '#/definitions/PiqWebredirectDeutschebankDeposit/properties/providerId':
          case '#/definitions/PiqWebredirectPostbankDeposit/properties/service':
          case '#/definitions/PiqWebredirectPostbankDeposit/properties/providerId':
          case '#/definitions/PiqWebredirectCommerzbankDeposit/properties/service':
          case '#/definitions/PiqWebredirectCommerzbankDeposit/properties/providerId':
          case '#/definitions/PiqWebredirectDeutschekreditbankDeposit/properties/service':
          case '#/definitions/PiqWebredirectDeutschekreditbankDeposit/properties/providerId':
          case '#/definitions/PiqWebredirectGate2wayDeposit/properties/service':
          case '#/definitions/PiqWebredirectGate2wayskrillDeposit/properties/service':
          case '#/definitions/PiqWebredirectGate2waynetellerDeposit/properties/service':
          case '#/definitions/PiqWebredirectGate2waysofortDeposit/properties/service':
          case '#/definitions/PiqWebredirectGate2waywebpayzDeposit/properties/service':
          case '#/definitions/PiqWebredirectGate2waygiropayDeposit/properties/service':
          case '#/definitions/PiqWebredirectGate2waypaysafecardDeposit/properties/service':
          case '#/definitions/PiqWebredirectGate2waypaysafecashDeposit/properties/service':
          case '#/definitions/PiqBanklocalVipwithdrawalJpWithdrawal/properties/service': {
            return FormComponents.HiddenInputField
          }

          case '#/definitions/PiqEcobanqDeposit/properties/password':
          case '#/definitions/PiqVegaDeposit/properties/password':
          case '#/definitions/PiqVegaWithdrawal/properties/password':
          case '#/definitions/PiqVenuspointDeposit/properties/password':
          case '#/definitions/PiqVenuspointWithdrawal/properties/password': {
            return FormComponents.PasswordInputField
          }

          case '#/definitions/PiqCryptocurrencyCryptopayDeposit/properties/cryptoCurrency':
          case '#/definitions/PiqCryptocurrencyCryptopayWithdrawal/properties/cryptoCurrency': {
            return FormComponents.CryptopayCurrencySelector
          }

          case `#/definitions/PiqCreditcardDeposit/properties/encCreditcardNumber`:
          case `#/definitions/PiqCreditcardMastercardDeposit/properties/encCreditcardNumber`:
          case `#/definitions/PiqCreditcardRupayDeposit/properties/encCreditcardNumber`:
          case `#/definitions/PiqCreditcardVisaDeposit/properties/encCreditcardNumber`:
          case `#/definitions/PiqCreditcardWithdrawal/properties/encCreditcardNumber`: {
            return FormComponents.CreditCardNumberInputField
          }

          default: {
            return null
          }
        }
      }}
    />
  )
}

FieldBuilder.propTypes = {
  initialValues: PropTypes.object,
  name: PropTypes.string,
}
